@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

$value :var(--rand-value);



*::-webkit-scrollbar {
  display: none;
}

.full {
  width: 100%;
}

.main {
  width: 100%;
  background-color: black;
}

.navBar {
  width: 100%;
  height: 7%;
  background-color: white;
  position: fixed;
}

.logo {
  width: 125px;
  height: 100%;
  margin-left: 2%;
}

.logo img{
  width: 100%;
  height: 100%;
}

.c {
  width: 100%;
  height: 100%;
  border: 0.1px solid black;
}

.me {
  width: 80%;
  height: 70%;
  margin: 10% auto;
  border-left: 3px solid red;
}

.personalInfo {
  width: 100%;
  height: 100%;
  display: flex;
}

.meDesc {
  width: 50%;
  height: 100%;
  background-color: rgb(0, 0, 0);
}

.ContainerName {
  margin: 0% auto;
  margin-top: 32%;
  width: 90%;
}

.ContainerAboutMe {
  margin: 0% auto;
  width: 90%;
  margin-top: 3%;
}

.name {
  font-family: "Geologica", sans-serif;
  color: white;
  font-size: 33px;
  font-weight: 500;
}

.aboutMe {
  font-family: "Geologica", sans-serif;
  color: white;
  font-size: 18px;
  font-weight: lighter;
}

.myPic {
  width: 50%;
  height: 100%;
  background-color: black;
  text-align: center;
}

.box {
  width: 380px;
  height: 380px;
  margin: 18% auto;
  border: 5px solid rgb(171, 1, 1);
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  background-image: linear-gradient(45deg, #08aeea, #2af598, 100%);
  animation: morph 4s ease-in-out infinite;
  background: url("./pic.png") no-repeat;
  background-size: 110%;
  background-position: top -10px center;
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;

  }
  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.socials {
  margin: 0% auto;
  width: 90%;
  margin-top: 3%;
}

.socials i {
  padding-right: 16px;
  color: red;
  font-size: 30px;
}



/* Responsive side */
@media (max-width: 1000px) {
  .me {
    margin-top: 20%;
    height: 60%;
  }
  .box {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 830px) {
  .me {
    margin-top: 21%;
    height: 78%;
  }
  .box {
    width: 300px;
    height: 300px;
  }
  .personalInfo {
    display: block;
    text-align: center;
  }

  .meDesc {
    height: 50%;
    width: 100%;
  }

  .ContainerName {
    margin: 0% auto;
    margin-top: 8%;
    width: 90%;
  }

  .myPic{
    margin-top: -14%;
    width: 100%;
    height: 100%;
    background-color: black;
    text-align: center;
  }
}

.under {
  width: 100%;
  background-color: white;
  height: 500px;
}

.card {
  width: 80%;
  height: 80%;
  margin: 6% auto;
}

.ill {
  width: 500px;
  height: 500px;
  margin: 4.5% auto;
  text-align: center;
}

.ill img {
  width: 100%;
  height: 100%;
}

.ill span {
  margin-top: 2.5%;
  font-family: "Geologica", sans-serif;
  color: red;
  font-weight: 500;
  font-size: 25px;
}

.logoPresenter {
  width: 100%;
}

.logoPresenterContainer {
  width: 280px;
  height: 180px;
  margin: 300px auto;
  text-align: center;
}

.logoPresenterContainer img {
  width: 100%;
  height: 100%;
}

.logoPresenterContainer .quote {
  font-family: "Geologica", sans-serif;
  font-size: 20px;
  font-style: italic;
  font-weight: 500;
}

//AboutMe cell

.cellContainer {
  width: 100%;
  height: fit-content;
  background-color: #000000;
}

.bx-play-circle {
  display: none;
}

.bx-expand-horizontal, .bx-play-circle {
  margin-top: 7px;
  font-size: 25px;
  color: white;
  cursor: pointer;
  transition: all 100ms;
}

.cell {
  margin: 2% auto;
  width: 80%;
  height: fit-content;
  display: flex;
  align-items: stretch;
  box-shadow: 1px 1px 5px gray;
  transition: all 1s;

  &:hover {
    .bx-play-circle {
      display: block;
    }

    .bx-expand-horizontal {
      display: none;
    }
  }
}

.exectablePart {
  width: 5%;
  background-color: rgb(127, 0, 0);
  text-align: center;
}

.codePart {
  width: 95%;
  cursor:text;
}

.codeHolder {
  resize: none;
  background-color: rgb(205, 205, 205);
  color: black;
  font-family: "Source Code Pro", monospace;
  padding: 10px;
}

.import {
  color: rgb(150, 86, 150);
}

.aboutMeDiv {
  margin: 2.5% auto;
  width: 360px;
  height: 360px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: red;
}

.aboutMeDiv img {
  width: 100%;
  height: 100%;
}

.titleContainer {
  font-family: "Geologica", sans-serif;
  height: fit-content;
  background-color: #08aeea;
  text-align: center;
  padding: 50px;
  font-size: 40px;
  font-weight: bolder;
  background-image: url("./bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}